import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withI18n, withI18nProps } from '@lingui/react'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import last from 'lodash/last'

import prepareComponent from 'client/shared/decorators/prepare-component'
import env from 'env'
import urlFor from 'shared/tools/url-helper'

import {
  IMPRESSIONS_SAMPLE,
  load,
  loadAuthorGroupedWorks,
  loadAuthorImpressions,
  loadAuthorLanguageVariants,
  loadAuthorQuotes,
  loadAuthorTopics,
  QUOTES_SAMPLE,
} from 'client/bookmate/reducers/author-reducer'
import {
  mapStateToPropsForAuthor,
  getAllTitles,
  MappedAuthorProps,
} from 'client/bookmate/helpers/author-helpers'

import Spacer from 'client/shared/blocks/spacer'
import Footer from 'client/shared/blocks/footer'
import HeaderBox from 'client/shared/boxes/header-box'
import MetaTwinBox from 'client/shared/boxes/meta-twin-box'
import MetaTitle from 'client/shared/blocks/meta-title'
import { MetaDescription } from 'client/shared/blocks/meta-description'
import MetaDeeplink from 'client/shared/blocks/meta-deeplink'
import Meta from 'client/shared/blocks/meta'

import { Dispatch, State } from 'shared/types/redux'
import { State as AppState } from 'client/shared/reducers/app-reducer'
import { CurrentUserState } from 'client/shared/types/current-user'
import { Location, Route } from 'client/shared/types/react-router'
import { AuthorInfo } from 'client/bookmate/blocks/author-info'

import { Loader } from 'client/shared/blocks/loader'
import { objectHasAnyPositiveProperty } from 'client/shared/helpers/tabs-helper'

type Props = {
  dispatch: Dispatch
  location: Location
  children: React.ReactNode
  app: AppState
  currentUser: CurrentUserState
  routes: Route[]
} & withI18nProps &
  MappedAuthorProps

class AuthorPage extends Component<Props> {
  render(): JSX.Element {
    const {
      author,
      authorMeta: {
        languageVariants,
        sectionsOrder,
        impressions: { content: impressions },
        quotes: { content: quotes },
      },
      location: { query, pathname },
      series,
      books,
      audiobooks,
      comicbooks,
    } = this.props

    const hasSeries = objectHasAnyPositiveProperty(series)
    const hasAudiobooks = objectHasAnyPositiveProperty(audiobooks)
    const hasBooks = objectHasAnyPositiveProperty(books)
    const hasComicbooks = objectHasAnyPositiveProperty(comicbooks)
    const booksAuthor =
      (hasBooks &&
        sectionsOrder.find(section => section.worksType === 'books')) ||
      false
    const audiobooksAuthor =
      (hasAudiobooks &&
        sectionsOrder.find(section => section.worksType === 'audiobooks')) ||
      false
    const comicbooksAuthor =
      (hasComicbooks &&
        sectionsOrder.find(section => section.worksType === 'comicbooks')) ||
      false

    return (
      <>
        {this.getMeta(hasBooks || hasAudiobooks || hasComicbooks)}
        <HeaderBox />
        {isEmpty(author) ? (
          <Loader />
        ) : (
          <AuthorInfo
            author={author}
            languageVariants={languageVariants}
            query={query}
            pathname={pathname}
            hasSeries={hasSeries}
            hasAudiobooks={hasAudiobooks}
            hasBooks={hasBooks}
            hasComicbooks={hasComicbooks}
            booksAuthor={booksAuthor}
            comicbooksAuthor={comicbooksAuthor}
            audiobooksAuthor={audiobooksAuthor}
            impressions={impressions}
            quotes={quotes}
          />
        )}
        {this.props.children}
        <Spacer size="bottom-page" />
        <Footer />
      </>
    )
  }

  getMeta(hasBooks: boolean): JSX.Element | null {
    if (isEmpty(this.props.author)) return null

    const {
      i18n,
      app: { host },
      authorMeta: { sectionsOrder },
      author: { uuid: authorId, name: authorName, about: aboutAuthor, locale },
      routes,
    } = this.props

    const isPublisher = sectionsOrder.every(section => {
      return section.role === 'publisher'
    })

    const titles = getAllTitles(this.props).join(', ')
    const title = isPublisher
      ? i18n._('author.meta_page_title_publisher', { publisher: authorName })
      : i18n._('author.meta_page_title', { author: authorName })
    const ogTitle = authorName || ''
    const description = isPublisher
      ? i18n._('author.meta_description_publisher_about', {
          publisher: authorName,
          about: aboutAuthor || titles,
          titles,
        })
      : i18n._('author.meta_description_about', {
          author: authorName,
          about: aboutAuthor || titles,
          titles,
        })

    const ogUrl = `https://${host}${urlFor.author(authorId)}`
    const isLanguages = last(routes)?.path === 'languages'

    let MetaTwinBoxProps
    if (isLanguages) {
      MetaTwinBoxProps = {
        robotsParams: {
          name: 'robots',
          content: 'noindex,follow',
        },
      }
    } else {
      MetaTwinBoxProps = {}
    }

    return (
      <>
        <MetaTwinBox language={locale} {...MetaTwinBoxProps} />
        <MetaTitle disableSuffix title={title} og={ogTitle} hasOgSuffix />
        <MetaDeeplink target="author" uuid={authorId} />
        <MetaDescription description={description} />
        <Meta content="summary_large_image" property="twitter:card" />
        <Meta
          content={urlFor.canvasAuthor({ authorId, mode: 'twitter' })}
          property="twitter:image"
        />
        <Meta
          content={urlFor.canvasAuthor({ authorId, mode: 'facebook' })}
          property="og:image"
        />
        <Meta content={ogUrl} property="og:url" />
        {!hasBooks && <Meta name="robots" content="noindex" />}
      </>
    )
  }
}

const prepareWrapper = prepareComponent(
  async function ({ store, params }) {
    const { authorId } = params
    const { dispatch } = store

    const actions = []

    if (env.isServer()) {
      // Dispatch this action before others to make sure that authorId is actually
      // an id and not author's name (as it will be if someone opens the old url).
      // If authorId is in fact author's name, api5 will respond with 404, and we will
      // get author's id from api4 and redirect to proper url.
      // That's why we need to wait until this request completes before sending other ones.
      // This sucks, I know.
      await dispatch(load(authorId))
    } else {
      // at least we can ignore the above problem on the client
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      actions.push(dispatch(load(authorId)))
    }

    actions.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(loadAuthorGroupedWorks(authorId)),
      dispatch(loadAuthorLanguageVariants(authorId)),
      dispatch(
        loadAuthorImpressions({ authorId, per_page: IMPRESSIONS_SAMPLE }),
      ),
      dispatch(loadAuthorQuotes({ authorId, per_page: QUOTES_SAMPLE })),
      dispatch(loadAuthorTopics(authorId)),
    )
    await Promise.all(actions)
  },
  { updateParams: 'authorId' },
)

const addHostWrapper = connect((state: State) => ({
  app: state.app,
  currentUser: state.currentUser,
}))

const connectWrapper = connect(mapStateToPropsForAuthor)

const wrappers = compose(
  prepareWrapper,
  withI18n({ update: true }),
  addHostWrapper,
  connectWrapper,
)

export default wrappers(AuthorPage)
