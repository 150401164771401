import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet-async'
import { connect } from 'react-redux'
import parse from 'url-parse'

import env from 'env'
import config from 'config'
import urlFor, { getSubdomainFromHostname } from 'shared/tools/url-helper'
import { getValidLocale } from 'shared/tools/locale-helpers'

import { State as AppState } from 'client/shared/reducers/app-reducer'
import { State } from 'shared/types/redux'

type Props = {
  app: AppState
  language: string
  robotsParams: {
    name?: string
    content?: string
  }
  customPathname?: string
  pagination: boolean
}

class MetaTwinBox extends PureComponent<Props> {
  static defaultProps = {
    language: config.fallbackLocale,
    robotsParams: {
      name: 'yandex',
      content: 'noindex,follow',
    },
    pagination: false,
  }

  render() {
    const {
      app: { host, url: serverUrl, domain },
      language: lang,
      robotsParams,
      customPathname,
      pagination,
    } = this.props

    const language = getValidLocale(lang)
    const subdomain = getSubdomainFromHostname(host) || config.fallbackLocale
    const isCanonical = language === subdomain
    if (isCanonical && !pagination && !customPathname) {
      return null
    }

    const url =
      customPathname ||
      (env.isClient()
        ? window.location.pathname
        : parse(serverUrl, {}).pathname)

    const link = urlFor.absoluteRootWithSubdomain(language, domain) + url

    return (
      <Helmet meta={[robotsParams]} link={[{ href: link, rel: 'canonical' }]} />
    )
  }
}

const connectWrapper = connect((state: State) => ({
  app: state.app,
}))

export default connectWrapper(MetaTwinBox)
